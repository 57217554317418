/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import { routes } from '../lib/routes'
import { useProductsByGroupAndYear } from '../lib/useProductsByGroupAndYear'
import { useSparepartGroups } from '../lib/useSparepartGroups'
import { useSparepartYearList } from '../lib/useSparepartYearList'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import { ErrorBlock } from './ErrorBlock'
import Select from './Select'

const StyledSelect = styled(Select as any)`
  select {
    border-color: ${theme.colors.border};
  }

  &:not(:first-of-type) {
    margin-top: ${theme.spacing.lg};
  }
` as typeof Select

export const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    min-width: 186px;
  }
`

interface FormValues {
  year: number
  productGroup: number
}

interface Props {
  className?: string
}

const SparepartsProductsByGroupAndYearForm = ({ className }: Props) => {
  const router = useRouter()
  const { data: sparepartYearList } = useSparepartYearList()
  const { data: sparepartGroups } = useSparepartGroups()
  const [loading, setLoading] = useState(false)
  const { submit, hasError, data } = useProductsByGroupAndYear()
  const { register, control, handleSubmit, watch, formState } =
    useForm<FormValues>()

  const yearValue = watch('year')
  const productGroupIdValue = watch('productGroup')

  const errorMessages = {
    required: t({
      message: 'This field is required',
    }),
  }

  const getProductGroupName = useCallback(
    () =>
      sparepartGroups?.find(
        (sparepartGroup) => sparepartGroup?.id === Number(productGroupIdValue),
      )?.name,
    [productGroupIdValue, sparepartGroups],
  )

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true)
    await submit(formValues.year, formValues.productGroup)
  }

  useEffect(() => {
    const productGroup = getProductGroupName()

    if (data && data?.length > 0 && productGroup) {
      router.push({
        pathname: routes.spareparts.results,
        query: {
          year: yearValue,
          productGroupId: productGroupIdValue,
          productGroup,
        },
      })
    } else {
      setLoading(false)
    }
  }, [data, getProductGroupName, productGroupIdValue, router, yearValue])

  const sortedSparepartGroups = sparepartGroups
    ? sortArrayByProperty(sparepartGroups, (group) => group?.name ?? null)
    : []

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <StyledSelect
        control={control}
        {...register('year', {
          required: {
            value: true,
            message: errorMessages.required,
          },
        })}
      >
        <>
          <option value="">
            <Trans>Product year</Trans>
          </option>

          {sparepartYearList?.map((year, index) =>
            year ? (
              <option key={index} value={year}>
                {year}
              </option>
            ) : null,
          )}
        </>
      </StyledSelect>

      <StyledSelect
        control={control}
        {...register('productGroup', {
          required: {
            value: true,
            message: errorMessages.required,
          },
        })}
      >
        <>
          <option value="">
            <Trans>Product group</Trans>
          </option>

          {sortedSparepartGroups?.map((sparepartGroup) =>
            sparepartGroup ? (
              <option key={sparepartGroup.id} value={sparepartGroup.id}>
                {sparepartGroup.name}
              </option>
            ) : null,
          )}
        </>
      </StyledSelect>

      <StyledButtonPrimary
        analyticsContext="spareparts"
        analyticsName="search.by.year.and.productgroup"
        colorType="neutral"
        disabled={!formState.isValid}
      >
        <Trans>Search</Trans>
      </StyledButtonPrimary>

      {loading && <Loader />}

      {hasError ? (
        <ErrorBlock
          title={t({
            message: 'Could not fetch products, please try again later.',
          })}
        />
      ) : null}

      {data?.length === 0 ? (
        <ErrorBlock
          title={t({ message: 'Sorry, we could not find products' })}
          listItems={[
            t({ message: 'Try another year' }),
            t({ message: 'Or search below using a product name or number' }),
          ]}
        />
      ) : null}
    </form>
  )
}

export default SparepartsProductsByGroupAndYearForm
