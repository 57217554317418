import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { GetStaticProps } from 'next'

import { minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'

import { addApolloState, initializeApollo } from '../../apollo/apolloClient'
import Breadcrumbs from '../../components/Breadcrumbs'
import Container from '../../components/Container'
import DefaultLayout from '../../components/DefaultLayout'
import HtmlContent from '../../components/HtmlContent'
import PageHeader from '../../components/PageHeader'
import ProductsBySkuOrNameForm from '../../components/ProductsBySkuOrNameForm'
import SparepartsProductsByGroupAndYearForm from '../../components/SparepartsProductsByGroupAndYearForm'
import { getCraftSiteProps } from '../../lib/getCraftSiteProps'
import getSeoPageDescription from '../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../lib/getSeoPageTitle'
import { routes } from '../../lib/routes'
import {
  DefaultLayoutStaticData,
  getStaticProps as getDefaultLayoutStaticProps,
} from '../../lib/useCraftGlobalSets'
import theme from '../../theme'
import { CraftSparepartsStaticData, sparepartsQuery } from './useSpareparts'

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  grid-column: 1 / -1;
`

const GridItem = styled.div`
  grid-column: 1 / -1;

  @media ${minWidth('md')} {
    grid-column: 1 / 10;
  }

  @media ${minWidth('lg')} {
    grid-column: 3 / 10;
  }
`

const WideGridItem = styled.div`
  margin-top: ${theme.spacing['3xl']};
  grid-column: 1 / -1;

  @media ${minWidth('md')} {
    grid-column: 1 / 12;
  }

  @media ${minWidth('lg')} {
    grid-column: 3 / 12;
  }
`

const StyledH2 = styled(H2)`
  margin-top: ${theme.pagePadding};
  font-size: ${theme.fontSizes['2xl']};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledSparepartsProductsBySkuOrNameForm = styled(ProductsBySkuOrNameForm)`
  margin-top: ${theme.spacing.lg};
`

const StyledSparepartsProductsByGroupAndYearForm = styled(
  SparepartsProductsByGroupAndYearForm,
)`
  margin-top: ${theme.spacing.lg};
`

type Props = DefaultLayoutStaticData & CraftSparepartsStaticData

const Spareparts = ({ websiteData, craftDataSpareparts }: Props) => {
  const sparepartsContent =
    craftDataSpareparts.__typename === 'CraftSparepartsEntry'
      ? craftDataSpareparts
      : undefined

  const headerImage = sparepartsContent?.image?.[0]

  const breadcrumbName = {
    name: t({ message: 'Spareparts' }),
  }

  return (
    <DefaultLayout
      websiteData={websiteData}
      hasPaddingTop={false}
      metaTitle={
        sparepartsContent?.metaTitle ??
        getSeoPageTitle(t({ message: 'Spareparts' }))
      }
      metaDescription={
        sparepartsContent?.metaDescription ?? getSeoPageDescription()
      }
      showMetaCanonical
    >
      {sparepartsContent?.title && headerImage && (
        <PageHeader
          title={sparepartsContent.title}
          subtitle={sparepartsContent.subtitle ?? undefined}
          image={headerImage}
        />
      )}

      <StyledContainer>
        <StyledBreadcrumbs customName={breadcrumbName} />

        <GridItem>
          <StyledH2>
            <Trans>Search spareparts</Trans>
          </StyledH2>
          <StyledSparepartsProductsByGroupAndYearForm />

          <StyledH2>
            <Trans>Or search by productname or productnumber</Trans>
          </StyledH2>

          <StyledSparepartsProductsBySkuOrNameForm
            searchType="spareparts"
            onSuccessRoutePathName={routes.spareparts.results}
          />
        </GridItem>

        <WideGridItem>
          {sparepartsContent?.richText ? (
            <HtmlContent html={sparepartsContent.richText} />
          ) : null}
        </WideGridItem>
      </StyledContainer>
    </DefaultLayout>
  )
}

export default Spareparts

export const getStaticProps: GetStaticProps = async ({
  locale,
  defaultLocale,
}) => {
  const client = initializeApollo(locale ?? defaultLocale ?? '')
  const { websiteData } = await getDefaultLayoutStaticProps(client, locale)

  const { data: sparepartsData } = await client.query({
    query: sparepartsQuery,
    variables: {
      ...getCraftSiteProps(locale),
    },
  })

  if (!sparepartsData.entry) {
    throw new Error('Fetching craft spareparts data failed')
  }

  return addApolloState(client, {
    props: {
      websiteData,
      craftDataSpareparts: sparepartsData.entry ?? undefined,
    },
  })
}
