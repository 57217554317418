import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import productCardFragment from '@emico-hooks/product-card-fragment'
import { useCallback, useState } from 'react'

import {
  ProductsByGroupAndYearQuery,
  ProductsByGroupAndYearQueryVariables,
} from './useProductsByGroupAndYear.generated'

const getProductsByGroupAndYearQuery = gql`
  query ProductsByGroupAndYear($year: Int!, $productGroup: Int!) {
    productsByGroupAndYear(year: $year, productGroup: $productGroup) {
      ...ProductCardFragment
    }
  }

  ${productCardFragment}
` as TypedDocumentNode<
  ProductsByGroupAndYearQuery,
  ProductsByGroupAndYearQueryVariables
>

/**
 * Query to fetch products by product group and year
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const { submit, hasError, data } = useProductsByGroupAndYear()
 * }
 * ```
 */
export function useProductsByGroupAndYear() {
  const client = useApolloClient()
  const [data, setData] = useState<ProductsByGroupAndYearQuery>()
  const [hasError, setHasError] = useState(false)

  const submit = useCallback(
    /**
     * @param year Year to find products in
     * @param productGroup productGroup ID to find products in
     */
    async (year: number, productGroup: number) => {
      setHasError(false)
      setData(undefined)

      try {
        const { data: productsByGroupAndYearData } = await client.query({
          query: getProductsByGroupAndYearQuery,
          variables: {
            year: Number(year),
            productGroup: Number(productGroup),
          },
        })

        setData(productsByGroupAndYearData)
      } catch (error) {
        setHasError(true)
      }
    },
    [client],
  )

  return {
    hasError,
    submit,
    data: data?.productsByGroupAndYear,
  }
}
