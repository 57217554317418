import { useQuery, gql, TypedDocumentNode } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { SparepartGroupsQuery } from './useSparepartGroups.generated'

const getSparepartGroupsQuery = gql`
  query SparepartGroups {
    sparepartGroups {
      id
      name
    }
  }
` as TypedDocumentNode<SparepartGroupsQuery>

/**
 * Query to get a list of spareparts groups
 *
 * @returns array of sparepart groups
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { data: sparepartGroups } = useSparepartGroups()
 *
 *  [...]
 * }
 * ```
 */

export function useSparepartGroups() {
  const { data, error, ...others } = useQuery(getSparepartGroupsQuery, {
    context: getCacheableContext(),
  })

  if (error) {
    throw error
  }

  return {
    ...others,
    data: data?.sparepartGroups,
  }
}
