import { useQuery, gql, TypedDocumentNode } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { SparepartYearListQuery } from './useSparepartYearList.generated'

const getSparepartYearListQuery = gql`
  query SparepartYearListQuery {
    sparepartYearList
  }
` as TypedDocumentNode<SparepartYearListQuery>

/**
 * Query to get sparepart year lists
 *
 * @returns array of sparepart year numbers
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const { data: sparepartYearList } = useSparepartYearList()
 *
 *  [...]
 * }
 * ```
 */

export function useSparepartYearList() {
  const { data, error, ...others } = useQuery(getSparepartYearListQuery, {
    context: getCacheableContext(),
  })

  if (error) {
    throw error
  }

  return {
    ...others,
    data: data?.sparepartYearList,
  }
}
